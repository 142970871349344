// Generated by Framer (41c59c7)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["a9zdv5SY6"];

const serializationHash = "framer-7vgSW"

const variantClassNames = {a9zdv5SY6: "framer-v-lxnmwa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "a9zdv5SY6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://erasmus-plus.ec.europa.eu/"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 22, intrinsicWidth: 104.5, pixelHeight: 44, pixelWidth: 209, src: "https://framerusercontent.com/images/5cb4CbsXFMyKiztL7Ztho0oizzc.png"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-lxnmwa", className, classNames)} framer-jbi64g`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"a9zdv5SY6"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7vgSW.framer-jbi64g, .framer-7vgSW .framer-jbi64g { display: block; }", ".framer-7vgSW.framer-lxnmwa { height: 40px; overflow: visible; position: relative; text-decoration: none; width: 190px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerX67WdMgVy: React.ComponentType<Props> = withCSS(Component, css, "framer-7vgSW") as typeof Component;
export default FramerX67WdMgVy;

FramerX67WdMgVy.displayName = "erasmus_";

FramerX67WdMgVy.defaultProps = {height: 40, width: 190};

addFonts(FramerX67WdMgVy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})